.contact-page {
    position: relative;
    overflow: clip;

    .contact-content {
        padding: 150px 13vw 100px;
        min-height: 80vh;

        .contact_title {
            font-size: 80px;
            line-height: 1.13;
            color: var(--black-color);
            font-weight: 100;
            margin-bottom: 60px;

            span {
                font-weight: 400;
            }
        }

        .contact-form {
            .input_grp {
                margin-bottom: 20px;
            }

            input {
                border: none;
                border-bottom: 1px solid var(--black-color);
                border-radius: 0;
                box-shadow: none;
                width: 100%;
                padding: 10px 0;
            }

            textarea {
                border: none;
                border-bottom: 1px solid var(--black-color);
                border-radius: 0;
                box-shadow: none;
                width: 100%;
            }

            .error-mess{
                color: red;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.5px;
                text-align: right;
                display: block;
            }

            .success-message{
                background-color: rgb(210, 255, 210);
                color: rgb(0, 87, 0);
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.5px;
                margin-bottom: 20px;
                padding: 15px;
                text-align: center;
            }

            .text-meter{
                color: #000;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.5px;
                text-align: right;
                display: block;
            }

            .unsuccess-message{
                background-color: rgb(255, 210, 210);
                color: rgb(87, 0, 0);
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.5px;
                margin-bottom: 20px;
                padding: 15px;
                text-align: center;
            }

            button {
                padding: 18px 30px;

                .text {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .spinner {
                        margin-top: -2px;
                    }
                }
            }
        }
    }
}

@include media-max(xxl) {
    .contact-page {
        .contact-content {
            padding: 140px 10vw;

            .contact_title {
                font-size: 70px;
            }
        }
    }
}

@include media-max(xl) {
    .contact-page {
        .custom-animation {
            top: -100px !important;
            right: 10% !important;
            transform: scale(1.8) !important;
        }

        .contact-content {
            padding: 120px 10vw;

            .contact_title {
                font-size: 60px;
            }
        }
    }
}

@include media-max(lg) {
    .contact-page {
        .custom-animation {
            top: -100px !important;
            right: 0% !important;
            transform: scale(1.3) !important;
        }

        .contact-content {
            padding: 100px 10vw;

            .contact_title {
                font-size: 50px;
            }
        }
    }
}

@include media-max(md) {
    .contact-page {
        .custom-animation {
            top: -100px !important;
            right: -40px !important;
            transform: scale(1) !important;
        }

        .contact-content {
            padding: 80px 10vw;

            .contact_title {
                font-size: 40px;
            }
        }
    }
}

@include media-max(sm) {
    .contact-page {
        .custom-animation {
            right: -40px !important;
            transform: scale(.8) !important;
        }

        .contact-content {
            padding: 60px 10vw;

            .contact_title {
                font-size: 38px;
            }
        }
    }
}