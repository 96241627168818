.main-page {
    .hero-section {
        width: 100%;
        min-height: 100vh;
        padding: 60px 13vw 140px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        position: relative;
        overflow: clip;

        .sec_content {
            width: 100%;
            position: relative;
            z-index: 4;

            .sec_title {
                font-size: 80px;
                line-height: 1.13;
                color: var(--white-color);
                font-weight: 100;
                margin-bottom: 60px;

                span {
                    font-weight: 400;
                }
            }

            .sec_dec {
                width: 50%;
                font-size: 18px;
                color: #a8a8a8;
                margin-bottom: 60px;
                font-weight: 200;
            }

            .sec_btn_grp {
                display: flex;
                gap: 10px;
            }
        }

        .hexagon-modal {
            // position: absolute;
            // width: 100%;
            // height: 100%;
            z-index: 4;
        }
    }

    .section2 {
        width: 100%;
        // min-height: 100vh;
        padding: 150px 13vw 150px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        overflow: clip;

        .lemp_background {
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            opacity: 1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .sec_title {
            font-size: 80px;
            line-height: 1.13;
            color: var(--black-color);
            font-weight: 100;
            margin-bottom: 60px;

            span {
                font-weight: 400;
            }
        }

        .sec_dec {
            font-size: 16px;
            color: #363636;
            font-weight: 200;
        }

        .sec_content {
            width: fit-content;
            margin-top: 60px;
            display: flex;
            align-items: center;
            gap: 25px;

            &:hover {
                .emailIcon {
                    transform: scale(1.03);
                    filter: brightness(105%);
                }
            }

            .emailIcon {
                width: 60px;
                height: 60px;
                background-color: #cde2ff;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 15px;
                border-radius: 50%;
                transition: .3s ease-in-out;

                // height: 50px;
                img {
                    width: 100%;
                    box-shadow: 0 0 10px 0 #d4d4d4;
                }
            }

            .content_title {
                font-size: 20px;
                color: #000;
                font-weight: 400;
            }

            .content_details {
                font-size: 16px;
                color: #525252;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

    }

    .section3 {
        width: 100%;
        // min-height: 100vh;
        padding: 200px 13vw 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        overflow: clip;

        .hexagon-modal {
            z-index: 4;
        }

        .quotes-data {
            position: absolute;
            right: 0;
            top: 100px;

            .quotes-content {
                font-size: 14px;
                color: #727272;
                font-weight: 100;
                text-transform: uppercase;
                letter-spacing: 1px;
            }

            .quotes-line {
                margin-left: auto;
                margin-top: 10px;
                width: 40vw;
                height: 1px;
                background-color: #727272;
            }
        }

        .sec_content {
            width: 100%;
            position: relative;
            z-index: 4;

            .sec_title {
                font-size: 80px;
                line-height: 1.13;
                color: var(--white-color);
                font-weight: 100;
                margin-bottom: 100px;
                text-align: right;
                display: flex;
                gap: 20px;
                flex-direction: column;

                button {
                    margin-left: auto;
                }

                span {
                    font-weight: 400;
                }
            }
        }

        .grid_sec {
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            .grid_box {
                padding: 40px 30px 25px;
                border: 1px solid #909090;
                border-bottom: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 30px;
                cursor: pointer;

                .box_title {
                    font-size: 20px;
                    line-height: 1.2;
                    color: var(--white-color);
                }

                .box_dec {
                    font-size: 16px;
                    line-height: 1.2;
                    color: #727272;
                    position: relative;
                    transition: 0.3s ease-in-out;
                    opacity: 0;
                    transform: translateY(10px);
                }

                .box_button {
                    margin-top: 45px;
                    width: 35px;
                    height: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    color: white;
                    background-color: #525252;
                    transition: 0.3s ease-in-out;

                    svg {
                        font-size: 14px;
                    }
                }

                &:hover {
                    .box_dec {
                        opacity: 1;
                        transform: translateY(0);
                    }

                    .box_button {
                        background: var(--primary-color);
                    }
                }
            }
        }
    }
}

@include media-max(xxl) {
    .main-page {
        .hero-section {
            padding: 80px 10vw 120px;

            .sec_content {
                .sec_title {
                    font-size: 70px;
                    margin-bottom: 50px;
                }

                .sec_dec {
                    width: 60%;
                }
            }

            .hexagon-modal {
                .custom-animation {
                    &:nth-child(2) {
                        transform: scale(2.0) !important;
                    }
                }
            }
        }

        .section2 {
            padding: 130px 10vw;

            .sec_title {
                font-size: 70px;
                margin-bottom: 50px;
            }

            .sec_content {
                gap: 15px;

                .emailIcon {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        .section3 {
            padding: 180px 10vw 0;
        }
    }
}

@include media-max(xl) {
    .main-page {
        .hero-section {
            padding: 80px 9vw 60px;
            justify-content: center;

            .sec_content {
                .sec_title {
                    font-size: 60px;
                    margin-bottom: 40px;
                }

                .sec_dec {
                    width: 70%;
                }
            }

            .hexagon-modal {
                .custom-animation {
                    &:nth-child(2) {
                        transform: scale(1.8) !important;
                    }
                }
            }
        }

        .section2 {
            padding: 120px 9vw;

            .sec_title {
                font-size: 60px;
                margin-bottom: 40px;
            }
        }

        .section3 {
            padding: 180px 9vw 0;

            .sec_content {
                .sec_title {
                    font-size: 60px;
                    margin-bottom: 50px;
                }
            }

            .grid_sec {
                grid-template-columns: repeat(2, 1fr);

                .grid_box {
                    .box_dec {
                        opacity: 1;
                        transform: translateY(0);
                    }

                    .box_button {
                        margin-top: 10px;
                    }
                }
            }

            .quotes-data {
                top: 80px;
            }
        }
    }
}

@include media-max(lg) {
    .main-page {
        .hero-section {
            .sec_content {
                .sec_title {
                    font-size: 45px;
                    margin-bottom: 25px;
                }

                .sec_dec {
                    width: 80%;
                    margin-bottom: 40px;
                }
            }

            .hexagon-modal {
                .custom-animation {
                    &:nth-child(2) {
                        transform: scale(1.4) !important;
                    }
                }
            }
        }

        .section2 {
            padding-top: 150px;

            .sec_title {
                font-size: 45px;
                margin-bottom: 25px;
            }

            .sec_content {
                gap: 15px;

                .emailIcon {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        .section3 {
            .sec_content {
                .sec_title {
                    font-size: 45px;
                }
            }

            .hexagon-modal {
                .custom-animation {
                    transform: scale(1.2) !important;
                }
            }
        }
    }
}

@include media-max(md) {
    .main-page {
        .hero-section {
            padding: 80px 5vw 60px;

            .sec_content {
                .sec_title {
                    font-size: 40px;
                    margin-bottom: 20px;
                }

                .sec_dec {
                    width: 90%;
                    margin-bottom: 30px;
                    font-size: 16px;
                }
            }

            .hexagon-modal {
                .custom-animation {
                    &:nth-child(2) {
                        transform: scale(1.2) !important;
                        right: -20px !important;
                    }
                }
            }
        }

        .section2 {
            padding: 60px 5vw;

            .sec_title {
                font-size: 40px;
                margin-bottom: 20px;
            }

            .sec_dec {
                font-size: 14px;
            }

            .sec_content {
                gap: 15px;


                .content_title {
                    font-size: 16px;
                    line-height: 1;
                }

                .content_details {
                    font-size: 14px;
                    line-height: 1;
                }

                .emailIcon {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        .section3 {
            padding: 150px 5vw 0;

            .sec_content {
                .sec_title {
                    font-size: 40px;
                    margin-bottom: 40px;
                }
            }

            .hexagon-modal {
                .custom-animation {
                    transform: scale(1) !important;
                    left: -100px !important;
                }
            }
        }
    }
}

@include media-max(sm) {
    .main-page {
        .hero-section {
            padding: 80px 5vw 60px;

            .sec_content {
                .sec_title {
                    font-size: 30px;
                    margin-bottom: 20px;
                }

                .sec_dec {
                    width: 90%;
                    margin-bottom: 30px;
                    font-size: 14px;
                }

                .sec_btn_grp {
                    flex-wrap: wrap;
                }
            }

            .hexagon-modal {
                .custom-animation {
                    &:nth-child(2) {
                        transform: scale(1) !important;
                        right: -20px !important;
                    }
                }
            }
        }

        .section2 {
            padding: 50px 20px;

            .lemp_background {
                top: 0;

                img {
                    transform: rotate(90deg);
                }
            }

            .sec_title {
                font-size: 30px;
                margin-bottom: 20px;
            }

            .sec_dec {
                font-size: 14px;
            }

            .sec_content {
                gap: 15px;
                margin-top: 40px;

                .emailIcon {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        .section3 {
            padding: 70px 20px 0;

            .sec_content {
                .sec_title {
                    font-size: 30px;
                    margin-bottom: 40px;
                }
            }

            .grid_sec {
                grid-template-columns: repeat(1, 1fr);

                .grid_box {
                    gap: 15px;
                    padding: 30px 20px 20px;

                    .box_title {
                        font-size: 18px;
                    }

                    .box_dec {
                        font-size: 14px;
                    }

                    .box_button {
                        width: 28px;
                        height: 28px;

                        svg {
                            font-size: 12px;
                        }
                    }
                }
            }

            .hexagon-modal {
                .custom-animation {
                    transform: scale(0.7) !important;
                    left: -150px !important;
                }
            }

            .quotes-data {
                display: none;
            }
        }
    }
}