.project-details_sec {
    overflow: clip;
    width: 100%;
    position: relative;

    .project-details_content {
        padding: 150px 13vw 150px;
        overflow-x: clip;

        .content_type {
            margin-top: 90px;
            margin-bottom: 30px;
            font-size: 18px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 500;
            color: var(--primary-color);
        }

        .content_title {
            font-size: 80px;
            line-height: 1.13;
            color: var(--black-color);
            font-weight: 100;
            margin-left: -5px;

            span {
                font-weight: 500;
            }
        }

        .content_tag {
            display: flex;
            align-items: center;
            margin: 40px 0 55px;
            gap: 15px;

            .tag {
                font-size: 16px;
                font-weight: 500;
                padding: 0;
                padding-right: 15px;
                color: var(--primary-color);
                border-right: 1px solid #0000003c;
            }

            .tag:last-child {
                border: 0;
            }
        }

        .content-main_poster {
            img {
                width: 100%;
            }
        }

        .content_dec {
            margin: 120px 0;

            .ls {
                font-size: 40px;
                font-weight: 500;
                line-height: 1.2;
                margin-bottom: 40px;
            }

            .rs {
                font-size: 17px;
                font-weight: 400;
                color: #00000080;
            }
        }

        .poster_grid {
            display: grid;
            gap: 30px;
            grid-template-columns: repeat(2, 1fr);
            position: relative;

            .poster_grid_item {
                img {
                    border: 1px solid #e4e4e4e0;
                    width: 100%;
                }
            }
        }
    }
}

.other-project {
    padding: 110px 13vw;
    background-color: rgb(236, 236, 236);

    .other-project_title {
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            padding-left: 35px;
        }
    }

    .content_grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;

        .grid_box {
            .image {
                overflow: hidden;
                width: 100%;
                cursor: pointer;

                img {
                    width: 100%;
                    transition: 0.4s ease-in-out;
                    transform: scale(1);
                }

            }

            &:hover {
                .image img {
                    transform: scale(1.03);
                }
            }

            .grid_content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;
            }

            .title {
                margin: 5px 0;
                font-weight: 500;
                font-size: 25px;
                transition: 0.4s ease-in-out;
                cursor: pointer;

                &:hover {
                    color: var(--primary-color);
                }
            }

            .type {
                margin: 5px 0;
                font-weight: 300;
                font-size: 16px;
                color: #4b4b4b;
            }

            .common_btn {
                padding: 0;
                background-color: transparent;
                color: #303030;

                .icon {
                    transition: 0.4s ease-in-out;
                    background-color: #d4d4d4;
                }

                &:hover {
                    color: var(--primary-color);

                    .icon {
                        background-color: var(--primary-color);
                        color: var(--white-color);
                    }
                }
            }
        }
    }
}


@include media-max(xxl) {
    .project-details_sec {
        .project-details_content {
            padding: 130px 10vw 130px;
        }
    }

    .other-project {
        padding: 120px 10vw;
    }
}


@include media-max(xl) {
    .project-details_sec {
        .custom-animation {
            top: -100px !important;
            right: 10% !important;
            transform: scale(1.8) !important;
        }

        .project-details_content {
            padding: 100px 9vw 100px;

            .content_type {
                margin-bottom: 10px;
            }

            .content_title {
                font-size: 70px;
                margin: 0;
            }

            .content_tag {
                margin: 35px 0 45px;
            }

            .content_dec {
                margin: 80px 0;

                .ls {
                    font-size: 35px;
                    margin-bottom: 30px;
                }
            }
        }
    }

    .other-project {
        padding: 100px 9vw 90px;

        .other-project_title {
            font-size: 30px;
        }

        .content_grid {
            gap: 40px;

            .grid_content {
                margin-top: 7px !important;
            }

            .grid_box {
                .title {
                    font-size: 22px;
                }

                .common_btn {
                    gap: 10px;

                    .icon {
                        width: 35px;
                        height: 35px;
                    }
                }
            }
        }
    }
}

@include media-max(lg) {
    .project-details_sec {
        .custom-animation {
            top: -100px !important;
            right: 0% !important;
            transform: scale(1.3) !important;
        }

        .project-details_content {
            padding: 100px 9vw 100px;

            .content_type {
                margin-bottom: 10px;
                font-size: 16px;
            }

            .content_title {
                font-size: 60px;
                margin: 0;
            }

            .content_tag {
                margin: 35px 0 45px;

                .tag {
                    font-size: 14px;
                }
            }

            .content_dec {
                margin: 70px 0;

                .ls {
                    font-size: 30px;
                    margin-bottom: 25px;
                }

                .rs {
                    font-size: 16px;
                }
            }
        }

    }

    .other-project {
        .other-project_grid {
            grid-template-columns: repeat(2, 1fr);
        }

        .content_grid {
            .grid_box {
                .common_btn {
                    .text {
                        display: none;
                    }
                }
            }
        }
    }
}

@include media-max(md) {
    .project-details_sec {
        .custom-animation {
            top: -100px !important;
            right: -40px !important;
            transform: scale(1) !important;
        }

        .project-details_content {
            padding: 100px 5vw 100px;

            .content_type {
                margin-bottom: 15px;
                font-size: 16px;
            }

            .content_title {
                font-size: 50px;
                margin: 0;
            }

            .content_tag {
                flex-wrap: wrap;
                gap: 10px 15px;
                margin: 35px 0 45px;
            }

            .content_dec {
                margin: 50px 0 30px;

                .ls {
                    font-size: 25px;
                    margin-bottom: 20px;
                }

                .rs {
                    font-size: 15px;
                }
            }
        }

    }

    .other-project {
        padding: 80px 5vw 70px;

        .other-project_title {
            font-size: 28px;

            button{
                padding-left: 25px;
            }
        }

        .content_grid {
            gap: 30px;

            .grid_box {
                .title {
                    font-size: 18px;
                    margin: 2px 0;
                }

                .type {
                    font-size: 14px;
                    margin: 2px 0;
                }

                .common_btn {
                    font-size: 12px;

                    .icon {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }

}

@include media-max(sm) {
    .project-details_sec {
        .custom-animation {
            right: -40px !important;
            transform: scale(.8) !important;
        }

        .project-details_content {
            padding: 50px 5vw 60px;

            .content_type {
                font-size: 14px;
            }

            .content_title {
                font-size: 32px;
            }

            .content_tag {
                margin: 30px 0 35px;
            }

            .content_dec {
                margin: 50px 0 30px;

                .ls {
                    font-size: 22px;
                    margin-bottom: 15px;
                }

                .rs {
                    font-size: 14px;
                }
            }

            .poster_grid {
                gap: 5vw;
                grid-template-columns: repeat(1, 1fr);
            }
        }

    }

    .other-project {
        padding: 80px 5vw 70px;

        .other-project_title {
            font-size: 24px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 15px;
            margin-bottom: 30px;
        }

        .content_grid {
            grid-template-columns: repeat(1, 1fr);

            .grid_box {
                .title {
                    font-size: 18px;
                    margin: 2px 0;
                }

                .type {
                    font-size: 14px;
                    margin: 2px 0;
                }

                .common_btn {
                    font-size: 12px;

                    .icon {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }
}