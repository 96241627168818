.project-page {
    position: relative;
    overflow: clip;

    .project-content {
        padding: 200px 13vw 150px;
        width: 100%;
        position: relative;

        .content_title {
            font-size: 80px;
            line-height: 1.13;
            color: var(--black-color);
            font-weight: 100;
            margin-left: -5px;
            margin-bottom: 60px;

            span {
                font-weight: 500;
            }
        }

        .content_dec {
            margin-bottom: 70px;

            .dec_title {
                font-size: 28px;
                line-height: 1.3;
                font-weight: 100;
                margin-bottom: 30px;

                span {
                    font-weight: 600;
                }
            }

            .dec_data {
                font-size: 16px;
                font-weight: 300;
                color: #727272;
            }
        }

        .content_grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 50px;

            .grid_box {
                .image {
                    overflow: hidden;
                    width: 100%;
                    cursor: pointer;

                    img {
                        width: 100%;
                        transition: 0.4s ease-in-out;
                        transform: scale(1);
                    }

                }

                &:hover {
                    .image img {
                        transform: scale(1.03);
                    }
                }

                .grid_content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 10px;
                }

                .title {
                    margin: 5px 0;
                    font-weight: 500;
                    font-size: 25px;
                    transition: 0.4s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        color: var(--primary-color);
                    }
                }

                .type {
                    margin: 5px 0;
                    font-weight: 300;
                    font-size: 16px;
                    color: #4b4b4b;
                }

                .common_btn {
                    padding: 0;
                    background-color: transparent;
                    color: #303030;

                    .icon {
                        transition: 0.4s ease-in-out;
                        background-color: #d4d4d4;
                    }

                    &:hover {
                        color: var(--primary-color);

                        .icon {
                            background-color: var(--primary-color);
                            color: var(--white-color);
                        }
                    }
                }
            }
        }
    }
}

@include media-max(xxl) {
    .project-page {
        .project-content {
            padding: 200px 10vw 150px;

            .content_title {
                font-size: 70px;
            }

            .content_dec {
                .dec_title {
                    font-size: 26px;
                }

                .dec_data {
                    font-size: 15px;
                }
            }

            .content_grid {
                gap: 40px;

                .grid_box {
                    .title {
                        font-size: 24px;
                    }

                    .type {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

@include media-max(xl) {
    .project-page {
        .custom-animation {
            top: -100px !important;
            right: 10% !important;
            transform: scale(1.8) !important;
        }

        .project-content {
            padding: 200px 9vw 150px;

            .content_title {
                font-size: 60px;
            }

            .content_dec {
                .dec_title {
                    font-size: 24px;
                }

                .dec_data {
                    font-size: 14px;
                }
            }

            .content_grid {
                gap: 30px;

                .grid_box {
                    .title {
                        font-size: 23px;
                    }

                    .type {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@include media-max(lg) {
    .project-page {
        .custom-animation {
            top: -100px !important;
            right: 0% !important;
            transform: scale(1.3) !important;
        }

        .project-content {
            padding: 200px 9vw 150px;

            .content_title {
                font-size: 50px;
            }

            .content_dec {
                margin-bottom: 50px;

                .dec_title {
                    font-size: 22px;
                }

                .dec_data {
                    font-size: 13px;
                }
            }

            .content_grid {
                gap: 20px;

                .grid_box {
                    .title {
                        font-size: 22px;
                    }

                    .type {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

@include media-max(md) {
    .project-page {
        .custom-animation {
            top: -100px !important;
            right: -40px !important;
            transform: scale(1) !important;
        }

        .project-content {
            padding: 200px 5vw 150px;

            .content_title {
                font-size: 40px;
            }

            .content_dec {
                margin-bottom: 40px;

                .dec_title {
                    font-size: 20px;
                }

                .dec_data {
                    font-size: 14px;
                }
            }

            .content_grid {
                gap: 15px;

                .grid_box {
                    .title {
                        font-size: 20px;
                    }

                    .type {
                        font-size: 12px;
                    }

                    .common_btn {
                        .text {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@include media-max(sm) {
    .project-page {
        .custom-animation {
            right: -40px !important;
            transform: scale(.8) !important;
        }

        .project-content {
            padding: 200px 5vw 50px;

            .content_title {
                font-size: 35px;
            }

            .content_dec {
                .dec_title {
                    font-size: 18px;
                }
            }

            .content_grid {
                grid-template-columns: repeat(1, 1fr);
                gap: 5vw;

                .grid_box {
                    .title {
                        font-size: 18px;
                    }

                    .type {
                        font-size: 11px;
                    }
                }
            }
        }
    }
}