.breadcrumb-main {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        top: 9px;
        left: calc(-100% - 15px);
        height: 1px;
        background-color: #bfbfbf;
        z-index: -1;
    }

    .breadcrumb {
        gap: 10px;

        li {
            color: var(--primary-color);
            font-size: 14px;
            letter-spacing: 1px;
            text-transform: uppercase;
            padding: 0;

            &.arrow {
                color: #2d2d2d;
            }

            a {
                color: #2d2d2d;
                text-decoration: none;
                transition: 0.2s ease-in-out;

                &:hover {
                    color: var(--primary-color);
                }
            }
        }
    }
}