:root {
    --primary-color: #e688ff;
    --primary-color: #2d83ff;
    --white-color: rgba(255, 255, 255, 0.9);
    --black-color: #000;
}

// Media query breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 575px,
    md: 767px,
    lg: 991px,
    xl: 1199px,
    xxl: 1399px) !default;