.detail-team_page {
    position: relative;
    overflow: clip;

    .details-team_content {
        padding: 150px 13vw 150px;
        min-height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        .details-team_leftSide {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 20px;

            .details-team_title {
                font-size: 60px;
                line-height: 1.13;
                color: var(--black-color);
                font-weight: 100;
                margin-bottom: 20px;

                span {
                    font-weight: 500;
                }
            }

            .details-team_designation {
                line-height: 1.33;
                font-size: 18px;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-weight: 500;
                color: var(--primary-color);
            }

            .details-team_dec {
                width: 70%;
                font-size: 16px;
                font-weight: 200;
                color: var(--black-color);
                margin-bottom: 20px;
            }

            .details-team_social-links {
                display: flex;
                align-items: center;
                gap: 10px;

                .social_link {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background-color: var(--primary-color);
                    font-size: 16px;
                    color: var(--white-color);
                    transition: .3s ease-in-out;

                    &:hover {
                        transform: scale(1.05);
                        filter: brightness(1.2);
                    }
                }
            }

        }

        .detail-team_profile {
            max-width: 450px;
            z-index: 10;
            position: relative;
            z-index: 3;

            img {
                width: 100%;
            }
        }
    }

    .detail-team_skill {
        padding: 150px 13vw;

        .detail-team_content {
            position: relative;
            z-index: 5;

            .skill_grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 80px 20px;

                .skill_item {
                    text-align: center;

                    .skill_name {
                        font-size: 22px;
                        font-weight: 500;
                        color: var(--white-color);
                        margin-bottom: 15px;
                    }

                    .skill_experience {
                        font-size: 16px;
                        font-weight: 300;
                        color: #aaaaaa;
                    }
                }
            }
        }
    }
}

@include media-max(xxl) {
    .detail-team_page {
        .details-team_content {
            .details-team_leftSide {
                .details-team_title {
                    font-size: 50px;
                }

                .details-team_designation {
                    font-size: 16px;
                }

                .details-team_dec {
                    width: 80%;
                }
            }
        }

        .detail-team_skill {
            padding: 140px 10vw;

            .detail-team_content {
                .skill_grid {
                    gap: 60px 20px;

                    .skill_item {
                        .skill_name {
                            font-size: 20px;
                        }

                        .skill_experience {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

@include media-max(xl) {
    .detail-team_page {
        .custom-animation {
            top: -100px !important;
            right: 10% !important;
            transform: scale(1.8) !important;
        }

        .details-team_content {
            padding: 150px 10vw 150px;

            .details-team_leftSide {
                .details-team_title {
                    font-size: 40px;
                }

                .details-team_designation {
                    font-size: 14px;
                }

                .details-team_dec {
                    width: 90%;
                }
            }
        }

        .detail-team_skill {
            padding: 130px 10vw;

            .detail-team_content {
                .skill_grid {
                    gap: 50px 20px;

                    .skill_item {
                        .skill_name {
                            font-size: 18px;
                        }

                        .skill_experience {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

@include media-max(lg) {
    .detail-team_page {
        .custom-animation {
            top: -100px !important;
            right: 0% !important;
            transform: scale(1.3) !important;
        }

        .details-team_content {
            padding: 130px 9vw 80px;

            .details-team_leftSide {
                .details-team_title {
                    font-size: 35px;
                }

                .details-team_designation {
                    font-size: 14px;
                }

                .details-team_dec {
                    width: 100%;
                }
            }
        }

        .detail-team_skill {
            padding: 100px 9vw;

            .detail-team_content {
                .skill_grid {
                    gap: 40px 20px;

                    .skill_item {
                        .skill_name {
                            font-size: 18px;
                        }

                        .skill_experience {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

@include media-max(md) {
    .detail-team_page {
        .custom-animation {
            top: -100px !important;
            right: -40px !important;
            transform: scale(1) !important;
        }

        .details-team_content {
            padding: 150px 5vw 80px;

            .details-team_leftSide {
                .details-team_title {
                    font-size: 35px;
                }
            }

            .detail-team_profile {
                max-width: 250px;
                margin: auto;
                margin-top: 30px;
            }
        }

        .detail-team_skill {
            padding: 100px 5vw;
        }
    }
}

@include media-max(sm) {
    .detail-team_page {
        .custom-animation {
            right: -40px !important;
            transform: scale(.8) !important;
        }
        
        .details-team_content {
            padding: 170px 5vw 80px;

            .details-team_leftSide {
                .details-team_title {
                    font-size: 32px;
                    margin-bottom: 0;
                }

                .details-team_dec {
                    margin-bottom: 0;
                    font-size: 14px;
                }

                .details-team_social-links {
                    .social_link {
                        width: 35px;
                        height: 35px;
                    }
                }
            }

            .detail-team_profile {
                max-width: 250px;
                margin: auto;
                margin-top: 30px;
            }

        }


        .detail-team_skill {
            padding: 100px 5vw;

            .detail-team_content {
                .skill_grid {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }
}