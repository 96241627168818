.project-list {
    padding: 120px 13vw;

    .project-list_title {
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            padding-left: 35px;
        }
    }

    .no_data {
        width: 100%;
        text-align: center;
        font-size: 20px;
        padding: 20px 0 0;
    }

    .content_grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;

        .grid_box {
            .image {
                overflow: hidden;
                width: 100%;
                cursor: pointer;

                img {
                    width: 100%;
                    transition: 0.4s ease-in-out;
                    transform: scale(1);
                }

            }

            &:hover {
                .image img {
                    transform: scale(1.03);
                }
            }

            .grid_content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;
            }

            .title {
                margin: 5px 0;
                font-weight: 500;
                font-size: 25px;
                transition: 0.4s ease-in-out;
                cursor: pointer;

                &:hover {
                    color: var(--primary-color);
                }
            }

            .type {
                margin: 5px 0;
                font-weight: 300;
                font-size: 16px;
                color: #4b4b4b;
            }

            .common_btn {
                padding: 0;
                background-color: transparent;
                color: #303030;

                .icon {
                    transition: 0.4s ease-in-out;
                    background-color: #d4d4d4;
                }

                &:hover {
                    color: var(--primary-color);

                    .icon {
                        background-color: var(--primary-color);
                        color: var(--white-color);
                    }
                }
            }
        }
    }
}

@include media-max(xxl) {
    .project-list {
        padding: 120px 10vw;
    }
}

@include media-max(xl) {
    .project-list {
        padding: 100px 9vw 90px;

        .project-list_title {
            font-size: 30px;
        }

        .content_grid {
            gap: 40px;

            .grid_content {
                margin-top: 7px !important;
            }

            .grid_box {
                .title {
                    font-size: 22px;
                }

                .common_btn {
                    gap: 10px;

                    .icon {
                        width: 35px;
                        height: 35px;
                    }
                }
            }
        }
    }
}

@include media-max(lg) {
    .project-list {
        .project-list_grid {
            grid-template-columns: repeat(2, 1fr);
        }

        .content_grid {
            .grid_box {
                .common_btn {
                    .text {
                        display: none;
                    }
                }
            }
        }
    }
}

@include media-max(md) {
    .project-list {
        padding: 80px 5vw 70px;

        .project-list_title {
            font-size: 28px;
        }

        .content_grid {
            gap: 30px;
            .grid_box {
                .title {
                    font-size: 18px;
                    margin: 2px 0;
                }

                .type {
                    font-size: 14px;
                    margin: 2px 0;
                }

                .common_btn {
                    font-size: 12px;
                    .icon {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }
}

@include media-max(sm) {
    .project-list {
        padding: 80px 5vw 70px;

        .project-list_title {
            font-size: 24px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 15px;
            margin-bottom: 30px;
        }

        .content_grid {
            grid-template-columns: repeat(1, 1fr);

            .grid_box {
                .title {
                    font-size: 18px;
                    margin: 2px 0;
                }

                .type {
                    font-size: 14px;
                    margin: 2px 0;
                }

                .common_btn {
                    font-size: 12px;
                    .icon {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }
}