@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

*, *:before, *:after {-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;}
*							{ margin: 0px; padding: 0px;}
body						{  font-size:14px; transition: none; font-family: "Outfit", sans-serif !important;}
img							{ border: 0px; max-width: 100%;}
ul,ol						{ list-style: none; margin: 0; padding: 0}
a							{ text-decoration: none; outline: none; display: inline-block; }
a:focus, a:active,a:visited	{ outline: none; border: 0px;}
a:hover                     { text-decoration: none;}
select:focus 				{ outline: none; }
input:focus 				{  outline: none; }
textarea:focus 				{  outline: none; }
textarea 					{ resize: none; }
/* {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-weight: <weight>;
    font-style: normal;
} */