.header {
    padding: 30px 60px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    // transition: z-index 0.5s ease-in-out;

    .header_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 100;

        .logo {
            text-decoration: none;
            font-size: 38px;
            font-weight: 200;
            transition: 0s;
            color: rgb(0, 0, 0);

            span {
                font-weight: 600;
                // color: var(--primary-color);
            }
        }

        button {
            padding: 0;
            width: 28px;
            background: transparent;
            border: none;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 8px;
            position: relative;

            span {
                width: 100%;
                height: 2px;
                background-color: rgb(0, 0, 0);
                border-radius: 2px;
                transition: 0.3s ease-in-out;

                &.span3 {
                    width: calc(100% - 6px);
                }
            }

            &.active {
                .span1 {
                    transform: translate(0px, 10px) rotate(-45deg);
                }

                .span2 {
                    opacity: 0;
                }

                .span3 {
                    width: 100%;
                    transform: translate(0px, -10px) rotate(45deg);
                }
            }
        }
    }

    &.header-open {
        z-index: 99;

        .header_top {
            .logo {
                color: rgb(255, 255, 255);
            }

            button {
                span {
                    background-color: rgb(255, 255, 255);
                }
            }
        }
    }
}

.header_menu {
    opacity: 1;
    background-color: transparent;
    background-color: rgb(0, 0, 0);
    transition: 0.35s ease-in-out;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;

    &.active {
        opacity: 1;
        right: 0;
        background-color: rgb(0, 0, 0);
    }

    .header_grid {
        display: grid;
        grid-template-columns: 3fr 4fr;
        width: 100%;
        height: 100%;

        .left_grid {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            ul {
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: flex-start;
                justify-content: center;
                margin: 0;

                li {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    transition: 0.2s ease-in-out;

                    &:hover {
                        transform: translateX(10px);
                    }

                    a {
                        font-size: 28px;
                        font-weight: 500;
                        color: var(--white-color);
                        opacity: 0.9;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

@include media-max(xxl) {
    .header {
        padding: 15px 40px;
        background-color: #101010;
        z-index: 99;

        .header_top {
            .logo {
                font-size: 34px;
                color: var(--white-color);
            }

            button {
                span {
                    background-color: var(--white-color);
                }
            }
        }
    }

    .header_menu {
        &.active {
            background-color: #101010;
        }
    }
}

@include media-max(xl) {
    .header_menu {
        .header_grid {
            .left_grid {
                ul {
                    gap: 8px;

                    li {
                        a {
                            font-size: 24px;
                        }
                    }
                }
            }
        }

        &.active {
            background-color: #101010;
        }
    }
}

@include media-max(md) {
    .header {
        padding: 15px 20px;

        .header_top {
            .logo {
                font-size: 30px;
            }
        }
    }

    .header_menu {
        .header_grid {
            .left_grid {
                ul {
                    gap: 6px;

                    li {
                        a {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}