footer {
    padding: 100px 13vw 100px;
    position: relative;
    overflow: clip;
    width: 100%;

    .footer-content {
        z-index: 4;
        position: relative;

        .logo {
            font-size: 38px;
            font-weight: 200;
            color: var(--white-color);

            span {
                font-weight: 600;
            }
        }

        .logo_dec {
            margin-top: 20px;
            font-size: 18px;
            color: #b7b7b7;
            font-weight: 200;
        }

        .grid_sec {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            .grid_box {
                padding: 20px 15px;

                &.navigation_links {
                    ul {
                        margin: 0;
                        padding: 0;

                        li {
                            margin: 4px 0;

                            a {
                                font-size: 24px;
                                font-weight: 500;
                                color: var(--white-color);
                                text-decoration: none;
                                transition: 0.2s ease-in-out;

                                &:hover {
                                    color: var(--primary-color);
                                    transform: translateX(5px);
                                }
                            }
                        }
                    }
                }

                &.secondary-navigation_links {
                    ul {
                        margin: 0;
                        padding: 0;

                        li {
                            margin: 4px 0;

                            a {
                                font-size: 16px;
                                color: #b7b7b7;
                                text-decoration: none;
                                transition: 0.2s ease-in-out;

                                &:hover {
                                    color: white;
                                    transform: translateX(5px);
                                }
                            }
                        }
                    }
                }
            }
        }

        .address-box {
            padding: 20px 15px;

            .address_title {
                font-size: 18px;
                font-weight: 500;
                color: var(--white-color);
                margin-bottom: 20px;
            }

            .address_dec {
                font-size: 16px;
                color: #b7b7b7;
            }
        }

        .all-right_sec {
            color: #b7b7b7;
            padding: 20px 0px;
            font-weight: 200;
        }
    }
}

@include media-max(xxl) {
    footer {
        padding: 100px 10vw 100px;
    }
}

@include media-max(xl) {
    footer {
        padding: 70px 9vw;

        .footer-content {
            .logo {
                font-size: 32px;
            }

            .logo_dec {
                font-size: 16px;
            }

            .grid_sec {
                .grid_box {
                    &.navigation_links {
                        ul {
                            li {
                                a {
                                    font-size: 20px;
                                }
                            }
                        }
                    }

                    &.secondary-navigation_links {
                        ul {
                            li {
                                margin: 2px 0;

                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }

            .address-box {
                .address_title {
                    margin-bottom: 15px;
                }

                .address_dec {
                    font-size: 14px;
                }
            }

            .all-right_sec {
                font-size: 14px;
            }
        }
    }
}

@include media-max(lg) {
    footer {
        padding: 50px 9vw 20px;

        .footer-content {
            .logo {
                font-size: 30px;
            }

            .logo_dec {
                font-size: 15px;
                margin-bottom: 20px;
            }

            .address-box {
                padding: 10px 0px;
            }

            .grid_sec {
                .grid_box {
                    padding: 15px 0;

                    &.navigation_links {
                        ul {
                            li {
                                a {
                                    font-size: 20px;
                                }
                            }
                        }
                    }

                    &.secondary-navigation_links {
                        ul {
                            li {
                                margin: 2px 0;

                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }

            .address-box {
                .address_title {
                    margin-bottom: 15px;
                }

                .address_dec {
                    font-size: 14px;
                }
            }

            .all-right_sec {
                font-size: 14px;
            }
        }
    }
}

@include media-max(md) {
    footer {
        padding: 50px 5vw 0px;

        .footer-content {
            .grid_sec {
                .grid_box {
                    &.navigation_links {
                        ul {
                            li {
                                a {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }

            .address-box {
                margin-bottom: 15px;

                .address_title {
                    font-size: 16px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@include media-max(sm) {
    footer {
        .footer-content {
            .grid_sec {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}