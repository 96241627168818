.custom-animation-frame {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    -webkit-animation: jump 10s linear infinite;
    animation: jump 10s linear infinite;
    pointer-events: none;
}

.custom-animation {
    position: absolute; 
    display: inline-block;
    width: 300px;
    height: 300px;
    opacity: .3;
    pointer-events: none;
    z-index: 3;
}

.custom-animation .custom-pentagon div {
    border-top: 0.1px solid rgb(255, 255, 255);
}

.custom-animation.dark-border .custom-pentagon div {
    border-top: 0.1px solid rgb(0, 0, 0);
}

@media screen and (max-width: 1400px) {
    .hideOnMd {
        display: none;
    }
}

.custom-dodecahedron {
    position: relative;
    left: 100px;
    top: 40px;
    width: 100px;
    height: 223px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-animation: rotate 100s infinite linear;
    animation: rotate 100s infinite linear;
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
        transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
    }

    to {
        -webkit-transform: rotateX(360deg) rotateY(0deg) rotateZ(-360deg);
        transform: rotateX(360deg) rotateY(0deg) rotateZ(-360deg);
    }
}

@keyframes rotate {
    from {
        -webkit-transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
        transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
    }

    to {
        -webkit-transform: rotateX(360deg) rotateY(0deg) rotateZ(-360deg);
        transform: rotateX(360deg) rotateY(0deg) rotateZ(-360deg);
    }
}

.custom-pentagon {
    position: absolute;
    width: 100px;
}

.custom-pentagon:nth-child(1) {
    -webkit-transform: rotateY(0.2turn) translateZ(69px) rotateX(26.5deg);
    transform: rotateY(0.2turn) translateZ(69px) rotateX(26.5deg);
}

.custom-pentagon:nth-child(6) {
    bottom: 0;
    -webkit-transform: rotateY(0.2turn) translateZ(-69px) rotateX(206.5deg);
    transform: rotateY(0.2turn) translateZ(-69px) rotateX(206.5deg);
}

.custom-pentagon:nth-child(2) {
    -webkit-transform: rotateY(0.4turn) translateZ(69px) rotateX(26.5deg);
    transform: rotateY(0.4turn) translateZ(69px) rotateX(26.5deg);
}

.custom-pentagon:nth-child(7) {
    bottom: 0;
    -webkit-transform: rotateY(0.4turn) translateZ(-69px) rotateX(206.5deg);
    transform: rotateY(0.4turn) translateZ(-69px) rotateX(206.5deg);
}

.custom-pentagon:nth-child(3) {
    -webkit-transform: rotateY(0.6turn) translateZ(69px) rotateX(26.5deg);
    transform: rotateY(0.6turn) translateZ(69px) rotateX(26.5deg);
}

.custom-pentagon:nth-child(8) {
    bottom: 0;
    -webkit-transform: rotateY(0.6turn) translateZ(-69px) rotateX(206.5deg);
    transform: rotateY(0.6turn) translateZ(-69px) rotateX(206.5deg);
}

.custom-pentagon:nth-child(4) {
    -webkit-transform: rotateY(0.8turn) translateZ(69px) rotateX(26.5deg);
    transform: rotateY(0.8turn) translateZ(69px) rotateX(26.5deg);
}

.custom-pentagon:nth-child(9) {
    bottom: 0;
    -webkit-transform: rotateY(0.8turn) translateZ(-69px) rotateX(206.5deg);
    transform: rotateY(0.8turn) translateZ(-69px) rotateX(206.5deg);
}

.custom-pentagon:nth-child(5) {
    -webkit-transform: rotateY(1turn) translateZ(69px) rotateX(26.5deg);
    transform: rotateY(1turn) translateZ(69px) rotateX(26.5deg);
}

.custom-pentagon:nth-child(10) {
    bottom: 0;
    -webkit-transform: rotateY(1turn) translateZ(-69px) rotateX(206.5deg);
    transform: rotateY(1turn) translateZ(-69px) rotateX(206.5deg);
}

.custom-pentagon:nth-child(11) {
    -webkit-transform: translateZ(69px) rotateX(-90deg);
    transform: translateZ(69px) rotateX(-90deg);
}

.custom-pentagon:nth-child(12) {
    bottom: 0;
    -webkit-transform: translateZ(-69px) rotateX(90deg);
    transform: translateZ(-69px) rotateX(90deg);
}

.custom-pentagon div {
    position: absolute;
    width: 100px;
    height: 69px;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
}

.custom-pentagon div:nth-child(1) {
    -webkit-transform: rotate(0.2turn);
    transform: rotate(0.2turn);
}

.custom-pentagon div:nth-child(2) {
    -webkit-transform: rotate(0.4turn);
    transform: rotate(0.4turn);
}

.custom-pentagon div:nth-child(3) {
    -webkit-transform: rotate(0.6turn);
    transform: rotate(0.6turn);
}

.custom-pentagon div:nth-child(4) {
    -webkit-transform: rotate(0.8turn);
    transform: rotate(0.8turn);
}

.custom-pentagon div:nth-child(5) {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
}

@-webkit-keyframes jump {
    0% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    50% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
    }

    100% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
}

@keyframes jump {
    0% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    50% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
    }

    100% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
}