.invert_back {
    position: relative;
    background-color: #f4f4f4;

    &::before {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;
        z-index: 3;
        -webkit-backdrop-filter: invert(100%);
        backdrop-filter: invert(100%);
    }
}

.common_btn {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border: none;
    letter-spacing: 2px;
    font-size: 14px;
    border-radius: 70px;
    --padding: 16px;
    padding: var(--padding) 50px;
    padding-right: var(--padding);
    text-transform: uppercase;
    font-weight: 500;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;

    &:hover {
        filter: brightness(120%);
        transform: scale(1.01);
    }

    .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.btn_primary {
        background-color: var(--primary-color);
        color: white;

        .icon {
            background-color: var(--black-color);
        }
    }

    &.btn_dark {
        background-color: transparent;
        color: white;

        .icon {
            background-color: #313131;
        }
    }
}

@include media-max(xl) {
    .common_btn{
        --padding: 12px;
        padding: var(--padding) 30px;
        padding-right: var(--padding);
    }
}

@include media-max(lg) {
    .common_btn{
        --padding: 16px;
        padding: var(--padding) 30px;
        padding-right: var(--padding);
        gap: 10px;

        .icon{
            width: 35px;
            height: 35px;
        }
    }
}

@include media-max(md) {
    .common_btn{
        --padding: 15px;
        padding: var(--padding) 25px;
        padding-right: var(--padding);
        gap: 10px;
        font-size: 13px;

        .icon{
            width: 34px;
            height: 34px;
        }
    }
}

@include media-max(sm) {
    .common_btn{
        --padding: 15px;
        padding: var(--padding) 24px;
        padding-right: var(--padding);
        gap: 10px;
        font-size: 12px;

        .icon{
            width: 30px;
            height: 30px;
        }
    }
}