.services_page {
    width: 100%;
    min-height: 100vh;
    padding: 150px 13vw 140px;
    position: relative;
    overflow: clip;

    .services_content {
        position: relative;
        z-index: 5;

        .services_title {
            margin: 50px 0 60px;
            font-size: 86px;
            line-height: 1.1;
            font-weight: 100;
            color: var(--white-color);

            span {
                font-weight: 600;
            }
        }

        .services_grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            position: relative;
            gap: 60px;

            .grid_box {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: stretch;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: -30px;
                    width: 2px;
                    height: 100%;
                    background: linear-gradient(0deg, #00000000 0%, #fbfbfb3d 50%, #00000000 100%);
                    z-index: -1;
                }

                &:nth-last-child(1)::after {
                    content: unset;
                }

                .box_title {
                    font-size: 25px;
                    line-height: 1.2;
                    font-weight: 600;
                    margin-bottom: 25px;
                    color: var(--white-color);
                    flex-grow: 1;
                    transition: .3s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        color: var(--primary-color);
                    }
                }

                .box_dec {
                    font-size: 16px;
                    color: rgba(255, 255, 255, 0.4);
                    font-weight: 300;
                    margin-bottom: 25px;
                }

                ul {
                    padding: 0;

                    li {
                        border-bottom: 1px solid #e8e8e82a;
                        padding: 20px 0;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        color: #ffffff99;

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }

                .common_btn {
                    padding: 0;
                    background-color: transparent;
                    color: #e5e5e5;
                    color: var(--primary-color);
                    opacity: 0.85;
                    justify-content: flex-start;

                    .icon {
                        color: var(--white-color);
                        background-color: #ffffff26;
                        transition: .2s ease-in-out;
                    }

                    &:hover {
                        .icon {
                            background-color: var(--primary-color);
                        }
                    }
                }
            }
        }
    }
}

@include media-max(xxl) {
    .services_page {
        padding: 130px 10vw 130px;
    }
}

@include media-max(xl) {
    .services_page {
        padding: 120px 9vw 120px;

        .custom-animation {
            top: -100px !important;
            right: 10% !important;
            transform: scale(1.8) !important;
        }

        .services_content {
            .services_title {
                font-size: 70px;
                margin: 40px 0 60px;
            }

            .services_grid {
                grid-template-columns: repeat(2, 1fr);

                .grid_box {
                    .box_title {
                        font-size: 22px;
                        margin-bottom: 20px;
                    }

                    .box_dec {
                        margin-bottom: 20px;
                    }

                    ul {
                        li {
                            padding: 16px 0;
                        }
                    }

                    .common_btn {
                        .icon {
                            width: 35px;
                            height: 35px;
                            font-size: 12px;
                        }
                    }

                    &:nth-child(2n)::after {
                        content: unset;
                    }
                }
            }
        }
    }
}

@include media-max(lg) {
    .services_page {
        padding: 100px 9vw 100px;

        .custom-animation {
            top: -100px !important;
            right: 0% !important;
            transform: scale(1.3) !important;
        }

        .services_content {
            .services_title {
                font-size: 50px;
                margin: 30px 0 50px;
            }

            .services_grid {
                .grid_box {
                    .box_title {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }

                    .box_dec {
                        margin-bottom: 10px;
                    }

                    ul {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

@include media-max(md) {
    .services_page {
        padding: 80px 5vw 80px;

        .custom-animation {
            top: -100px !important;
            right: -40px !important;
            transform: scale(1) !important;
        }
        
        .services_content {
            .services_title {
                font-size: 40px;
                margin-bottom: 40px;
            }

            .services_grid {
                .grid_box {
                    .box_title {
                        font-size: 18px;
                        margin-bottom: 15px;
                    }

                    .box_dec {
                        font-size: 15px;
                        margin-bottom: 5px;
                    }

                    .common_btn {
                        font-size: 12px;

                        .icon {
                            width: 30px;
                            height: 30px;
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }
}

@include media-max(sm) {
    .services_page {

        .custom-animation {
            right: -40px !important;
            transform: scale(.8) !important;
        }

        .services_content {
            .services_grid {
                grid-template-columns: repeat(1, 1fr);
                gap: 50px;

                .grid_box {
                    &::after {
                        content: unset;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: -25px;
                        left: 0px;
                        width: 100%;
                        height: 2px;
                        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(251, 251, 251, 0.2392156863) 50%, rgba(0, 0, 0, 0) 100%);
                        z-index: -1;
                    }

                    &:nth-last-child(1)::before {
                        content: unset;
                    }
                }
            }
        }
    }
}