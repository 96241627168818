.our-team {
    width: 100%;
    padding: 100px 13vw 100px;
    position: relative;
    overflow: clip;
    z-index: 1;

    .sec_title {
        margin-top: 70px;
        font-size: 60px;
        line-height: 1.13;
        color: var(--black-color);
        font-weight: 600;
        margin-bottom: 30px;
    }

    .sec_dec {
        font-size: 18px;
        font-weight: 200;
    }

    .sec_content {
        margin-top: 70px;
        margin-bottom: 50px;
        font-size: 35px;
        line-height: 1.13;
        color: var(--black-color);
        font-weight: 100;

        span {
            font-weight: 500;
        }
    }

    .grid_sec {
        display: grid;
        gap: 40px;
        grid-template-columns: repeat(2, 1fr);

        .team_box {
            position: relative;
            overflow: hidden;
            max-width: 300px;

            .profile-Image {
                height: 100%;
                overflow: hidden;
                position: relative;
                z-index: 1;

                img {
                    transition: 0.4s ease-in-out;
                    transform: scale(1.04);
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .profile_content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: 0.4s ease-in-out;
                background-color: transparent;
                z-index: 10;

                .profile-details {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    color: white;
                    gap: 15px;
                    line-height: 1;
                    padding-bottom: 80px;
                    position: relative;
                    transition: 0.4s ease-in-out;
                    opacity: 0;
                    transform: translateY(10px);

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0px;
                        left: 0;
                        width: 100%;
                        height: 0px;
                        transition: 0.4s ease-in-out;
                        background-color: var(--primary-color);
                    }

                    .profile-name {
                        font-size: 18px;
                        cursor: pointer;
                        transition: 0.4s ease-in-out;
                        font-weight: 500;

                        &:hover {
                            color: var(--primary-color);
                        }
                    }

                    .profile-designation {
                        font-size: 14px;
                        color: #d0d0d0;
                        font-weight: 300;
                        letter-spacing: 1px;
                        text-align: center;
                    }

                    .link-grp {
                        display: flex;
                        gap: 15px;

                        a {
                            color: white;
                            font-size: 16px;
                            transition: 0.4s ease-in-out;

                            &:hover {
                                color: var(--primary-color);
                            }
                        }
                    }
                }

            }

            &:hover {
                .profile-Image {
                    img {
                        transform: scale(1);
                    }
                }

                .profile_content {
                    background-color: #000000c3;

                    .profile-details {
                        opacity: 1;
                        transform: translateY(0px);

                        &::after {
                            height: 8px;
                        }
                    }
                }
            }

            &:nth-child(even) {
                transform: translateY(50%);
            }

        }
    }
}

@include media-max(xxl){
    .our-team {
        padding: 100px 10vw 100px;
    }
}

@include media-max(xl) {
    .our-team {
        padding: 100px 9vw 100px;

        .sec_title {
            font-size: 50px;
            margin-top: 35px;
        }

        .sec_content {
            font-size: 30px;
        }
    }
}

@include media-max(lg) {
    .our-team {
        padding: 80px 9vw;

        .sec_title {
            font-size: 40px;
            margin-top: 0px;
        }

        .sec_dec {
            font-size: 16px;
        }

        .sec_content {
            font-size: 26px;
            margin-top: 60px;
        }

        .grid_sec {
            gap: 15px;
            grid-template-columns: repeat(3, 1fr);

            .team_box:nth-child(even) {
                transform: translateY(0);
            }
        }
    }
}

@include media-max(md) {
    .our-team {
        padding: 50px 5vw;

        .sec_title {
            font-size: 32px;
        }

        .sec_dec {
            font-size: 15px;
        }

        .sec_content {
            font-size: 22px;
            margin-top: 40px;
            margin-bottom: 30px;
        }

        .grid_sec {
            gap: 15px;
            grid-template-columns: repeat(3, 1fr);

            .team_box {
                .profile_content {
                    .profile-details {
                        padding-bottom: 40px;
                        gap: 10px;

                        .profile-name {
                            font-size: 14px;
                        }

                        .profile-designation {
                            font-size: 12px;
                        }

                        .link-grp {
                            a {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .team_box:nth-child(even) {
                transform: translateY(0);
            }
        }
    }
}

@include media-max(sm) {
    .our-team {
        padding: 35px 5vw;

        .sec_title {
            font-size: 28px;
            margin-bottom: 15px;
        }

        .sec_dec {
            font-size: 14px;
        }

        .sec_content {
            font-size: 22px;
            margin-top: 40px;
            margin-bottom: 30px;
        }

        .grid_sec {
            gap: 15px;
            grid-template-columns: repeat(1, 1fr);

            .team_box {
                max-height: 250px;
                max-width: unset;

                .profile_content {
                    .profile-details {
                        padding-bottom: 40px;
                        gap: 10px;

                        .profile-name {
                            font-size: 14px;
                        }

                        .profile-designation {
                            font-size: 12px;
                        }

                        .link-grp {
                            a {
                                font-size: 14px;
                            }
                        }
                    }
                }

                &:nth-child(2){
                    .profile-Image{
                        img{
                            height: unset;
                            top: -130px;
                            position: relative;
                        }
                    }
                }
                &:nth-last-child(1){
                    .profile-Image{
                        img{
                            height: unset;
                            top: -40px;
                            position: relative;
                        }
                    }
                }
            }

            .team_box:nth-child(even) {
                transform: translateY(0);
            }
        }
    }
}