.details-services-page {
    width: 100%;
    min-height: 100vh;
    padding: 150px 13vw 140px;
    position: relative;
    overflow: clip;

    .services_content {
        position: relative;
        z-index: 1;

        .content_title {
            margin: 50px 0 60px;
            font-size: 86px;
            line-height: 1.2;
            font-weight: 100;
            text-transform: capitalize;

            span {
                font-weight: 600;
            }
        }

        .content_dec {
            .dec_title {
                font-size: 28px;
                line-height: 1.3;
                font-weight: 100;
                margin-bottom: 40px;

                span {
                    font-weight: 600;
                }
            }

            .dec_data {
                font-size: 16px;
                font-weight: 300;
                color: #727272;
            }

            .content_tag {
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: column;


                li {
                    padding: 30px 0;
                    color: #222222;
                    font-weight: 500;
                    border-bottom: 1px solid #222222;
                    font-size: 14px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    transition: 0.2s ease-in-out;

                    &:last-child {
                        border: none;
                    }

                    .icon {
                        font-size: 18px;
                        transition: 0.2s ease-in-out;
                        color: #727272;
                    }

                    &:hover {
                        color: var(--primary-color);

                        .icon {
                            color: var(--primary-color);
                        }
                    }
                }
            }
        }
    }
}

.other-services {
    padding: 110px 13vw;
    background-color: rgb(236, 236, 236);

    .other-services_title {
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            padding-left: 35px;
        }
    }

    .other-services_grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;

        .grid_box {
            border: 1px solid #959595;
            padding: 40px 40px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .box_title {
                cursor: pointer;
                font-size: 25px;
                font-weight: 500;
                line-height: 1.2;
                margin-bottom: 30px;
                transition: 0.2s ease-in-out;
                flex-grow: 1;

                &:hover {
                    color: var(--primary-color);
                }
            }

            .box_dec {
                font-size: 16px;
                color: #6d6d6d;
                font-weight: 300;
                flex-grow: 10;
                margin-bottom: 30px;
            }

            ul {
                padding: 0;
                width: 100%;

                li {
                    padding: 20px 0;
                    font-weight: 500;
                    border-bottom: 1px solid #cbcbcb;

                    &:last-child {
                        border: none;
                    }
                }
            }

            .common_btn {
                margin-top: 15px;
                background-color: transparent;
                color: black;
                padding: 0;
                transition: .2s ease-in-out;

                .icon {
                    transition: 0.4s ease-in-out;
                    background-color: #d4d4d4;
                }


                &:hover {
                    filter: none;
                    transform: none;
                    color: var(--primary-color);

                    .icon {
                        background: var(--primary-color);
                        transform: scale(1.05);
                        color: white;
                    }
                }
            }
        }
    }
}

@include media-max(xxl) {
    .details-services-page {
        padding: 150px 10vw 140px;

        .services_content {
            .content_title {
                font-size: 70px;
                margin: 40px 0 50px;

            }

            .content_dec {
                .dec_title {
                    font-size: 24px;
                    margin-bottom: 30px;
                }
            }
        }
    }

    .other-services {
        padding: 120px 10vw 120px;

        .other-services_grid {
            .grid_box {
                padding: 35px;
            }
        }
    }
}

@include media-max(xl) {
    .details-services-page {
        padding: 120px 9vw 90px;

        .custom-animation {
            top: -100px !important;
            right: 10% !important;
            transform: scale(1.8) !important;
        }

        .services_content {
            .content_title {
                font-size: 60px;
                margin: 30px 0 40px;
            }

            .content_dec {
                .dec_title {
                    font-size: 22px;
                    margin-bottom: 25px;
                }
            }
        }
    }

    .other-services {
        padding: 100px 9vw 90px;

        .other-services_title {
            font-size: 30px;
        }

        .other-services_grid {
            .grid_box {
                padding: 22px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .box_title {
                    font-size: 22px;
                    margin-bottom: 20px;
                }

                .box_dec {
                    margin-bottom: 20px;
                }

                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        padding: 15px 0;
                    }
                }

                .common_btn {
                    margin-top: 15px;
                    justify-content: flex-start;
                    font-size: 12px;

                    .icon {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }
}

@include media-max(lg) {
    .details-services-page {
        padding: 140px 9vw 80px;

        .custom-animation {
            top: -100px !important;
            right: 0% !important;
            transform: scale(1.3) !important;
        }

        .services_content {
            .content_title {
                font-size: 50px;
                margin: 20px 0 30px;
            }

            .common_btn {
                margin-top: 20px !important;
                margin-bottom: 20px;
            }
        }
    }

    .other-services {
        .other-services_grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@include media-max(md) {
    .details-services-page {
        padding: 140px 5vw 70px;

        .custom-animation {
            top: -100px !important;
            right: -40px !important;
            transform: scale(1) !important;
        }

        .services_content {
            .content_title {
                font-size: 40px;
                margin: 10px 0 20px;
            }

            .content_dec {
                .dec_title {
                    font-size: 20px;
                    margin-bottom: 20px;
                }

                .content_tag {
                    li {
                        padding: 20px 0;
                        font-size: 12px;
                        .icon{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .other-services {
        padding: 80px 5vw 70px;

        .other-services_title {
            font-size: 28px;
        }

        .other-services_grid {
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;

            .grid_box {
                .box_title {
                    font-size: 20px;
                    margin-bottom: 15px;

                    br {
                        display: none;
                    }
                }

                .box_dec {
                    font-size: 14px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}

@include media-max(sm) {
    .details-services-page {
        padding: 140px 5vw 70px;

        .custom-animation {
            right: -40px !important;
            transform: scale(.8) !important;
        }

        .services_content {
            .content_title {
                font-size: 32px;
                margin: 10px 0 20px;
            }

            .content_dec {
                .dec_title {
                    font-size: 18px;
                    margin-bottom: 20px;
                }

                .dec_data {
                    font-size: 14px;
                }
            }
        }
    }

    .other-services {
        padding: 80px 5vw 70px;

        .other-services_title {
            font-size: 24px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 15px;
        }

        .other-services_grid {
            grid-template-columns: repeat(1, 1fr);
            gap: 15px;

            .grid_box {
                .box_title {
                    font-size: 20px;
                    margin-bottom: 15px;

                    br {
                        display: none;
                    }
                }

                .box_dec {
                    font-size: 14px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}